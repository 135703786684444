import { Route, Routes, Link } from "react-router-dom";
import { createContext, useState } from "react";
import Home from "./pages/HomePage/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import LogIn from "./pages/LogIn/LogIn";
import Paymentsuccessful from "./pages/PaymentSuccessful/Paymentsuccessful";
import "./App.css";
import TourOperator from "./pages/TourOperator/TourOperator";
import Booking from "./pages/Booking/Booking";
import NotFound from "./pages/Error/NotFound";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import moment from "moment";
import { v4 as uuid } from "uuid";
const globalContext = createContext(null);
const homepageContext = createContext(null);
const App = () => {
    const [homepage, setHomePage] = useState({
        fee: [],
        destination: [],
        touroperator: [],
        package: [],
        country: [],
        province: [],
        city: [],
    });
    const [global, setGlobal] = useState({
        traveldate: moment().format("YYYY-MM-DD"),
        numberofguest: 1,
        guestinfo: [
            {
                firstname: "",
                lastname: "",
                middlename: "",
                gender: "",
                mobilenumber: "",
                email: "",
                dob: "",
                age: 0,
                address: "",
                zipcode: "",
                citizen: "",
                citizen_desc: "",
                country: "",
                country_desc: "",
                province: "",
                province_desc: "",
                municipality: "",
                municipality_desc: "",
                id: uuid(),
            },
        ],
        activeguest: 1,
        selectedpck: "",
        selectedopr: "",
        selecteddes: "",
        totalAmount: 0,
        paymentsummary:[]
    });
    const handleGlobal = (value) => {
        setGlobal(value);
    };
    const value = {
        global,
        handleGlobal,
    };

    return (
        <globalContext.Provider value={{ global, setGlobal }}>
            <homepageContext.Provider value={{ homepage, setHomePage }}>
                <div className="App">
                    {/* {JSON.stringify(global)} */}
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Home" element={<Home />} />
                        <Route
                            path="/booking/destionation/:destid/touroperatorid/:id/packageid/:pckid"
                            element={<Booking />}
                        />
                        <Route path="/booking" element={<Booking />} />
                        <Route
                            path="/TourOperator"
                            element={<TourOperator />}
                        />
                        <Route path="/AboutUs" element={<AboutUs />} />
                        <Route path="/ContactUs" element={<ContactUs />} />
                        <Route path="/LogIn" element={<LogIn />} />
                        <Route
                            path="/Paymentsuccessful"
                            element={<Paymentsuccessful />}
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                   
                </div>
            </homepageContext.Provider>
        </globalContext.Provider>
    );
};

export default App;
export { globalContext, homepageContext };
