import React, { useState, useRef } from "react"
import Nav from "../Nav"
import BookingStesp from "./BookingSteps"

export default function Booking() {
    return (
        <>
            <Nav className="static" />
            <BookingStesp />
        </>
    )
}
