import React from "react";

export default function Button({ buttonName, btnClassName }) {
    return (
        <button
            className={`drop-shadow hidden px-4 py-2 text-xl text-white transition-all border-none rounded-lg md:block lg:text-lg hover:opacity-75 bg-yellowColor ${btnClassName}`}
        >
            {buttonName}
        </button>
    );
}
