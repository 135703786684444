import React from "react"
import Nav from "../Nav"
import Header from "./Header"

export default function TourOperator() {
    return (
        <div>
            <Nav />
            <Header />
        </div>
    )
}
