import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Button from "./Components/Button";
import logo from "../assets/images/logo.png";
export default function Nav(props) {
    // const [className, setClassName] = useState(props.className)
    const [open, setOpen] = useState(false);
    const [homeNav, setHomeNav] = useState("");

    let url = window.location.pathname;

    if (homeNav === "") {
        setHomeNav(url);
    }

    return (
        <div
            className={`z-20 flex justify-center items-center w-full bg-transparent drop-shadow-lg ${
                props.className === "static" ? "" : "fixed"
            }`}
        >
            <div className="flex flex-row items-center max-w-[1440px] w-full mx-3 text-xs">
                {/* logo */}
                <div className="cursor-pointer">
                    <Link to="/Home">
                        <img className="w-10 md:w-20" src={logo} alt="logo" />
                    </Link>
                </div>
                {/* nav items */}
                <div
                    className={`absolute top-0 transition-all ease-in-out w-4/5 h-screen flex flex-col pt-10 gap-5 ml-auto text-xl md:text-white bg-gray-50 md:bg-transparent md:p-0 md:h-fit md:static md:justify-center md:mx-auto lg:text-lg md:flex-row lg:w-fit 
                    ${open ? "right-0" : "right-[-800px]"}
                    ${homeNav === "/Home" ? "" : "text-black"}
                        `}
                >
                    <div className="text-center transition-all cursor-pointer navbar-ul hover:text-yellowColor">
                        <NavLink className="cursor-pointer" to="/Home">
                            Home
                        </NavLink>
                    </div>
                    <div className="text-center transition-all cursor-pointer navbar-ul hover:text-yellowColor">
                        {/* <NavLink className="cursor-pointer" to="/AboutUs"> */}
                        About
                        {/* </NavLink> */}
                    </div>
                    <div className="text-center transition-all cursor-pointer navbar-ul hover:text-yellowColor">
                        <NavLink className="cursor-pointer" to="/booking">
                            Book
                        </NavLink>
                    </div>
                    <div className="text-center transition-all cursor-pointer navbar-ul hover:text-yellowColor">
                        {/* <NavLink className="cursor-pointer" to="/ContactUs"> */}
                        Contact
                        {/* </NavLink> */}
                    </div>
                    {/* <ul className="navbar-ul lg:w-full hover:text-black transition-all text-center cursor-pointer drop-shadow-xl px-2 text-[#2A9D8F]">
                        <NavLink className="cursor-pointer" to="/LogIn">Log In</NavLink>
                    </ul> */}
                </div>
                {/* CTA yelloweBtn */}
                <Button btnClassName={""} buttonName={"Discover"} />
                <div
                    onClick={() => {
                        setOpen(!open);
                    }}
                    className={`grid ml-auto text-2xl md:hidden place-items-center ${
                        open ? "text-black" : "text-white"
                    }`}
                >
                    <ion-icon name={open ? "close" : "menu"}></ion-icon>
                </div>
            </div>
        </div>
    );
}
