import React, { useContext } from "react";
import { useRef } from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import { InputNumber } from "primereact/inputnumber";
import { globalContext, homepageContext } from "../../App";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect } from "react";

export default function BookingGuest() {
    const { global, setGlobal } = useContext(globalContext);
    const { homepage } = useContext(homepageContext);
    const [localGuest, setlocalGuest] = useState();
    const [mindate, setMindate] = useState(moment().format("YYYY-MM-DD"));
    const options = [
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
        { value: "3", label: "Other" },
    ];
    const [selectedOptionGender, setSelectedOptionGender] = useState();
    const [selectedOptionCountry, setSelectedOptionCountry] = useState();
    const [selectedOptionProvince, setSelectedOptionProvince] = useState();
    const [selectedOptionMunicipality, setSelectedOptionMunicipality] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const handleChangeOption = (e, f) => {
        
      //  //console.log(global["guestinfo"][global.activeguest - 1]);
        let newdata ;
        switch (f) {
            case "gender":
                if (e == null) {
                    setSelectedOptionGender(null);
                }else{
                    newdata = global["guestinfo"].map((item) => {
                        if (item.id === global["guestinfo"][global.activeguest - 1].id) {
                            const updatedItem = {
                                ...item,
                                ['gender']: e,
                            };
            
                            return updatedItem;
                        }
                        return item;
                    });
                    setSelectedOptionGender(e);
                    //console.log(newdata);
                }
                break;
            case "country":
                if (e == null) {
                    setSelectedOptionCountry(null);
                }else{
                    newdata = global["guestinfo"].map((item) => {
                        if (item.id === global["guestinfo"][global.activeguest - 1].id) {
                            const updatedItem = {
                                ...item,
                                ['country']: e,
                            };
            
                            return updatedItem;
                        }
                        return item;
                    });
                    setSelectedOptionCountry(e);
                    //console.log(newdata);
                }
                break;
            case "province":
                if (e == null) {
                    setSelectedOptionProvince(null);
                }else{
                    newdata = global["guestinfo"].map((item) => {
                        if (item.id === global["guestinfo"][global.activeguest - 1].id) {
                            const updatedItem = {
                                ...item,
                                ['province']: e,
                            };
            
                            return updatedItem;
                        }
                        return item;
                    });
                    setSelectedOptionProvince(e);
                    //console.log(newdata);
                }
                break;
            case "municipality":
                if (e == null) {
                    setSelectedOptionMunicipality(null);
                }else{
                    newdata = global["guestinfo"].map((item) => {
                        if (item.id === global["guestinfo"][global.activeguest - 1].id) {
                            const updatedItem = {
                                ...item,
                                ['municipality']: e,
                            };
            
                            return updatedItem;
                        }
                        return item;
                    });
                    setSelectedOptionMunicipality(e);
                    //console.log(newdata);
                }
            break;
        }
        if(newdata!=null){
            setGlobal({ ...global, ["guestinfo"]: newdata });
        }
        

       
        // switch (f) {
        //     case "o":
        //         if (e == null) {
        //             setSelectedOperator(null)
        //             setGlobal((prevState) => ({
        //                 ...prevState,

        //                 selectedpck: "",
        //                 selectedopr: "",
        //             }))
        //         } else {
        //             let opr = homepage?.touroperator.find(
        //                 (x) => x.id == e?.value
        //             )
        //             setSelectedOperator(opr)
        //             setGlobal({
        //                 ...global,
        //                 ["selectedopr"]: e?.value,
        //             })
        //         }
        //         break
        //     case "p":
        //         if (e == null) {
        //             setSelectedPackage(null)
        //             setGlobal((prevState) => ({
        //                 ...prevState,

        //                 selectedpck: "",
        //             }))
        //         } else {
        //             let pck = homepage?.package.find((x) => x.id == e?.value)
        //             setSelectedPackage(pck)
        //             setGlobal({ ...global, ["selectedpck"]: e?.value })
        //         }

        //         break
        //     default:
        //         break
        // }
    }
    const handleGuestChange = (e) => {
        ;
        const { id, name, value } = e.target;

        //console.log(global["guestinfo"][global.activeguest - 1]);
        const newdata = global["guestinfo"].map((item) => {
            if (item.id === id) {
                const updatedItem = {
                    ...item,
                    [name]: value,
                };

                return updatedItem;
            }
            return item;
        });

        // alert(JSON.stringify(newdata));
        setGlobal({ ...global, ["guestinfo"]: newdata });
        ;
    };

    return (
        <>
            
            <Form className="grid grid-cols-1 gap-3 p-3 lg:grid-cols-2">
                <Form.Group as={Col}>
                    <div className="flex gap-2 flex-column">
                        <label htmlFor="username">First Name</label>
                        <InputText
                            id={global["guestinfo"][global.activeguest - 1]?.id}
                            name="firstname"
                            placeholder="Enter First Name"
                            value={
                                global["guestinfo"][global.activeguest - 1]
                                    ?.firstname
                            }
                            onChange={(e) => handleGuestChange(e)}
                        />
                    </div>
                </Form.Group>
                <Form.Group as={Col}>
                    <div className="flex gap-2 flex-column">
                        <label htmlFor="lastname">Last Name</label>
                        <InputText
                            id={global["guestinfo"][global.activeguest - 1]?.id}
                            name="lastname"
                            value={
                                global["guestinfo"][global.activeguest - 1]
                                    ?.lastname
                            }
                            placeholder="Enter Last Name"
                            onChange={(e) => handleGuestChange(e)}
                        />
                    </div>
                </Form.Group>

                <Form.Group as={Col}>
                    <div className="flex gap-2 flex-column">
                        <label htmlFor="middlename">Middle Name</label>
                        <InputText
                            id={global["guestinfo"][global.activeguest - 1]?.id}
                            value={
                                global["guestinfo"][global.activeguest - 1]
                                    ?.middlename
                            }
                            name="middlename"
                            placeholder="Enter Middle Name"
                            onChange={(e) => handleGuestChange(e)}
                        />
                    </div>
                </Form.Group>
                <Form.Group as={Col}>
                    <label htmlFor="gender">Gender</label>
                    <span className="p-float-label">
                    
                        <Select
                            name="gender"
                            value={

                                global["guestinfo"][global.activeguest - 1]
                            ?.gender==""?null:
                                options.find(obj => obj.value ===global["guestinfo"][global.activeguest - 1]
                            ?.gender?.value)} 
                            isClearable="true"
                            //defaultValue={selectedOptionGender}
                            onChange={(e) => handleChangeOption(e,'gender')}
                            options={options}
                        />
                        {/* <label htmlFor="gender">Middle Name</label> */}
                    </span>
                    {/* <Form.Label>Gender</Form.Label> */}
                </Form.Group>

                <Form.Group as={Col}>
                    <div className="flex gap-2 flex-column">
                        <label htmlFor="phonenumber">Phone Number</label>
                        <InputMask
                            id={global["guestinfo"][global.activeguest - 1]?.id}
                            mask="(999) 999-9999"
                            placeholder="(999) 999-9999"
                            name="mobilenumber"
                            value={
                                global["guestinfo"][global.activeguest - 1]
                                    ?.mobilenumber
                            }
                            onChange={(e) => handleGuestChange(e)}
                        ></InputMask>
                    </div>
                </Form.Group>
                <Form.Group as={Col}>
                    <div className="flex gap-2 flex-column">
                        <label htmlFor="email">Email</label>
                        <InputText 
                        id={global["guestinfo"][global.activeguest - 1]?.id} 
                        value={
                            global["guestinfo"][global.activeguest - 1]
                                ?.email
                        }
                        onChange={(e) => handleGuestChange(e)}
                        name="email"  placeholder="Enter email id" />
                    </div>
                </Form.Group>

                <Form.Group as={Col}>
                    <div className="flex gap-2 flex-column">
                        <label htmlFor="address">Address</label>
                        <InputTextarea name="address"  id={global["guestinfo"][global.activeguest - 1]?.id} 
                        value={
                            global["guestinfo"][global.activeguest - 1]
                                ?.address
                        }
                        onChange={(e) => handleGuestChange(e)} rows={3} cols={30} />
                    </div>
                </Form.Group>

                <div className="col-start-1">
                    <Form.Group as={Col}>
                        <div className="flex gap-2 flex-column">
                            <label htmlFor="country">Country</label>
                            <Select
                                id="country"
                                isClearable="true"
                                value={
                                    
                                    global["guestinfo"][global.activeguest - 1]
                            ?.country==""?null:
                            homepage.country.find(obj => obj.value ===global["guestinfo"][global.activeguest - 1]
                            ?.country.value)
                                    
                                    } 
                                //defaultValue={selectedOptionCountry}
                               // onChange={setSelectedOption}
                                onChange={(e) => handleChangeOption(e,'country')}
                                optionLabel="name"
                                options={homepage.country}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <div className="flex gap-2 flex-column">
                            <label htmlFor="province">Province</label>
                            <Select
                                id="province"
                                isClearable="true"
                                value={
                                    
                                    global["guestinfo"][global.activeguest - 1]
                            ?.province==""?null:
                            homepage.province.find(obj => obj.value ===global["guestinfo"][global.activeguest - 1]
                            ?.province.value)
                                    
                                    } 
                               // defaultValue={selectedOptionProvince}
                                onChange={(e) => handleChangeOption(e,'province')}
                                options={homepage.province}
                            />
                        </div>
                    </Form.Group>
                </div>

                <div>
                    <Form.Group as={Col}>
                        <div className="flex gap-2 flex-column">
                            <label htmlFor="municipality">Municipality</label>
                            <Select
                                id="municipality"
                                isClearable="true"
                               // defaultValue={selectedOptionMunicipality}
                               value={
                                    
                                global["guestinfo"][global.activeguest - 1]
                        ?.municipality==""?null:
                        homepage.city.find(obj => obj.value ===global["guestinfo"][global.activeguest - 1]
                        ?.municipality.value)
                                
                                }  
                               onChange={(e) => handleChangeOption(e,'municipality')}
                                options={homepage.city}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <div className="flex gap-2 flex-column">
                            <label htmlFor="zipcode">Zipcode</label>
                            <InputText
                                
                                name="zipcode"  id={global["guestinfo"][global.activeguest - 1]?.id} 
                        value={
                            global["guestinfo"][global.activeguest - 1]
                                ?.zipcode
                        }
                        onChange={(e) => handleGuestChange(e)}
                                placeholder="Enter zipcode"
                            />
                        </div>
                    </Form.Group>
                </div>
            </Form>
        </>
    );
}
