import React from "react";

export default function LandingPage() {
    return (
        <main className="relative flex flex-col items-center justify-center w-full px-3 bg-no-repeat bg-cover h-fit bg-home-image">
            <div className="absolute w-full h-full backdrop-brightness-50" />
            <div className="max-w-[1080px] mb-[10rem] w-full">
                <div className="flex flex-col justify-center w-full text-left text-white h-[80vh] md:h-screen md:px-5">
                    <p
                        data-aos="fade-in"
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        className="-mb-5 md:text-[1.5rem] md:-mb-10 text-yellowColor drop-shadow-lg"
                    >
                        Paradise on Earth is in
                    </p>
                    <p
                        data-aos="fade-up"
                        data-aos-delay="1000"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        className="md:text-[17rem] font-kapakana text-[7rem] sm:text-[10rem] m-0 drop-shadow-lg"
                    >
                        Boracay
                    </p>
                </div>
                <div className="flex flex-col items-center justify-center gap-[5rem]">
                    <div
                        data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        className="z-10 flex flex-col items-center justify-center text-center"
                    >
                        <p className=" text-white text-[2rem]">Why Boracay?</p>
                        <p className=" text-yellowColor text-[1.5rem]">
                            FIVE REASONS WHY YOU SHOULD VISIT BORACAY
                        </p>
                    </div>
                    <ul className="relative flex flex-col md:grid grid-cols-2 py-[2rem] px-0 text-white gap-x-40 place-items-center">
                        {/* separator */}
                        <div className="absolute hidden md:block w-[1px] h-full bg-yellowColor" />
                        <li
                            data-aos="fade-in"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            className="flex m-0"
                        >
                            Pristine White Sands: Boracay boasts some of the
                            world's most stunning white sand beaches, perfect
                            for relaxation and water activities.
                        </li>
                        <li
                            data-aos="fade-in"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            className="flex col-start-2 row-start-2 m-0 text-right"
                        >
                            Vibrant Nightlife: Experience lively beach parties
                            and vibrant nightlife scene, offering endless
                            entertainment options after the sun sets.
                        </li>
                        <li
                            data-aos="fade-in"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            className="flex col-start-1 row-start-3 m-0"
                        >
                            Water Sports Paradise: From snorkeling and scuba
                            diving to kiteboarding and parasailing, Boracay
                            offers an array of thrilling water sports
                            adventures.
                        </li>
                        <li
                            data-aos="fade-in"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            className="flex col-start-2 row-start-4 m-0 text-right"
                        >
                            Breathtaking Sunsets: Witness mesmerizing sunsets
                            painting the sky with a kaleidoscope of colors,
                            creating unforgettable moments every evening.
                        </li>
                        <li
                            data-aos="fade-in"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            className="flex col-start-1 row-start-5 m-0"
                        >
                            Culinary Delights: Indulge in a diverse culinary
                            scene, featuring fresh seafood, local delicacies,
                            and international cuisines, ensuring a gastronomic
                            delight for every palate.
                        </li>
                    </ul>
                </div>
            </div>

            {/* <div className="hidden md:block custom-shape-divider-bottom-1714194741">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                        class="shape-fill"
                    ></path>
                </svg>
            </div> */}
        </main>
    );
}
