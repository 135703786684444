import React from "react";
import Button from "../Components/Button";

export default function SecondPage() {
    return (
        <section className="flex flex-col items-center justify-center w-full my-10">
            <div className="w-full max-w-[1080px] flex gap-5 flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                    <p className="m-0 text-[2rem]">F.A.Q.</p>
                    <p className="m-0 text-[1.5rem]">Popular Questions</p>
                </div>
                <ul className="p-0 m-0">
                    <li>1. When is the best time to visit Boracay?</li>
                    <li>2. What are the top activities to do in Boracay?</li>
                    <li>3. How do I get to Boracay from Manila?</li>
                    <li>
                        4. Are there any travel restrictions or entry
                        requirements for visiting Boracay?
                    </li>
                    <li>
                        5. What are the best areas to stay in Boracay for
                        different preferences and budgets?
                    </li>
                </ul>
                <Button buttonName={"Learn More"} />
            </div>
        </section>
    );
}
