import React from "react"
import Nav from "../Nav"

export default function LogIn() {
    return (
        <div>
            <Nav />
            <div className="border-2 h-screen bg-blue-500"></div>
        </div>
    )
}
