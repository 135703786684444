import React from "react";

export default function Footer() {
    return (
        <footer className="flex flex-col w-full py-5 text-white h-fit bg-blueColor">
            {/* <div className="h-screen bg-no-repeat bg-cover bg-footer-image"></div> */}
            <div className="flex flex-col items-center justify-center w-full h-screen gap-10 bg-blueColor">
                <div className="px-3 w-full max-w-[1080px]">
                    <div>
                        <p className="text-[2rem]">Get in touch</p>
                        <p className="text-[1.5rem]">
                            Feel free to connect with us!
                        </p>
                    </div>
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col">
                            <label htmlFor="">Name</label>
                            <input
                                placeholder="Name"
                                className="p-3 rounded-xl"
                                type="text"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="">Phone Number</label>
                            <input
                                placeholder="Phone Number"
                                className="p-3 rounded-xl"
                                type="number"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="">Message</label>
                            <textarea
                                className="p-3 rounded-xl"
                                placeholder="Your Message"
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full px-3">
                    <div className="h-[1px] w-full bg-white"></div>
                    <div className=" mt-2 flex flex-col md:flex-row justify-between w-full max-w-[1080px]">
                        <div>
                            <p className="m-0 p-0 text-[2rem] w-full">
                                Province of Aklan
                            </p>
                            <p className="m-0 p-0 text-[1.5rem] text-yellowColor w-full">
                                “The First Province of the Philippines”
                            </p>
                        </div>
                        <div className="text-left md:text-right">
                            <p className="w-full p-0 m-0 text-yellowColor">
                                Contacts
                            </p>
                            <p className="w-full p-0 m-0">02 1234 5678</p>
                            <p className="w-full p-0 m-0">boracay@mail.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
