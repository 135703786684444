import React from "react";
import Nav from "../Nav";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import image1 from "../../assets/images/undraw_beach_day_cser.svg";
import image2 from "../../assets/images/undraw_before_dawn_re_hp4m.svg";
import image3 from "../../assets/images/undraw_ready_for_waves_vlke.svg";
import image4 from "../../assets/images/undraw_relaxation_re_ohkx.svg";
import image5 from "../../assets/images/undraw_surfer_re_hncq.svg";
export default function AboutUs() {
    const [date, setDate] = useState(null);
    return (
        <div>
            <Nav />

            <main className="w-full">
                <section className="py-10 bg-gray-100 sm:py-16 lg:py-24 font-jakarta">
                    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                        <div className="grid max-w-4xl grid-cols-2 mx-auto text-center gap-y-20 place-items-center">
                            <h2
                                data-aos="fade-down"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 text-3xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl"
                            >
                                About us
                            </h2>

                            <div
                                data-aos="fade-right"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1"
                            >
                                <h3 className="text-2xl">
                                    Our Puerto Princesa Story
                                </h3>
                                <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-center text-gray-500 md:text-left">
                                    Learn about the rich history and culture of
                                    Puerto Princesa, the eco-tourism capital of
                                    the Philippines. Discover how this city has
                                    evolved into a sustainable and captivating
                                    destination for travelers from around the
                                    world.
                                </p>
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1 w-[70%]"
                            >
                                <img
                                    className="max-w-full"
                                    src={image1}
                                    alt=""
                                />
                            </div>

                            <div
                                data-aos="fade-left"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1 w-[70%]"
                            >
                                <img
                                    className="max-w-full"
                                    src={image2}
                                    alt=""
                                />
                            </div>
                            <div
                                data-aos="fade-right"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1"
                            >
                                <h3 className="text-2xl">
                                    Our Passion for Palawan
                                </h3>
                                <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-center text-gray-500 md:text-left">
                                    Dive into our deep-seated love for Palawan
                                    and Puerto Princesa. Uncover the commitment
                                    and dedication that drive us to promote
                                    environmental conservation, community
                                    engagement, and unforgettable experiences in
                                    this natural paradise.
                                </p>
                            </div>

                            {/* <div 
                                data-aos="fade-right"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="flex flex-col items-center w-full col-span-2 gap-5 "
                            >
                                <div className="grid w-full grid-cols-1 gap-5 place-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                    <div className="bg-[#d9d9d9] hover:opacity-50 cursor-pointer transition-all rounded-lg w-full h-[20rem]"></div>
                                    <div className="bg-[#d9d9d9] hover:opacity-50 cursor-pointer transition-all rounded-lg w-full h-[20rem]"></div>
                                    <div className="bg-[#d9d9d9] hover:opacity-50 cursor-pointer transition-all rounded-lg w-full h-[20rem]"></div>
                                    <div className="bg-[#d9d9d9] hover:opacity-50 cursor-pointer transition-all rounded-lg w-full h-[20rem]"></div>
                                </div>
                            </div> */}

                            <div
                                data-aos="fade-right"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1"
                            >
                                <h3 className="text-2xl">
                                    Meet the Faces Behind Puerto Princesa
                                </h3>
                                <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-center text-gray-500 md:text-left">
                                    Get to know the team that's dedicated to
                                    making your Puerto Princesa experience
                                    exceptional. Learn about our local experts,
                                    guides, and the people who are passionate
                                    about sharing the beauty of this pristine
                                    destination.
                                </p>
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1 w-[70%]"
                            >
                                <img
                                    className="max-w-full"
                                    src={image3}
                                    alt=""
                                />
                            </div>

                            <div
                                data-aos="fade-right"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1 w-[70%]"
                            >
                                <img
                                    className="max-w-full"
                                    src={image4}
                                    alt=""
                                />
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1"
                            >
                                <h3 className="text-2xl">
                                    Preserving Paradise: Our Sustainability
                                    Mission
                                </h3>
                                <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-center text-gray-500 md:text-left">
                                    Explore our commitment to preserving the
                                    unique and fragile ecosystem of Puerto
                                    Princesa. Discover the sustainable practices
                                    and initiatives we support to ensure that
                                    this natural wonder remains untouched for
                                    future generations.
                                </p>
                            </div>

                            {/* <div
                                data-aos="fade-left"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="flex flex-col items-center w-full col-span-2 gap-5"
                            >
                                <div className="grid w-full grid-cols-1 gap-5 place-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                    <div className="bg-[#d9d9d9] hover:opacity-50 cursor-pointer transition-all rounded-lg w-full h-[20rem]"></div>
                                    <div className="bg-[#d9d9d9] hover:opacity-50 cursor-pointer transition-all rounded-lg w-full h-[20rem]"></div>
                                    <div className="bg-[#d9d9d9] hover:opacity-50 cursor-pointer transition-all rounded-lg w-full h-[20rem]"></div>
                                    <div className="bg-[#d9d9d9] hover:opacity-50 cursor-pointer transition-all rounded-lg w-full h-[20rem]"></div>
                                </div>
                            </div> */}

                            <div
                                data-aos="fade-right"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1"
                            >
                                <h3 className="text-2xl">
                                    Puerto Princesa through Our Eyes
                                </h3>
                                <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-center text-gray-500 md:text-left">
                                    See Puerto Princesa from a different
                                    perspective through the eyes of our team.
                                    Find out what makes this city so special to
                                    us and how we aim to make it just as
                                    extraordinary for you during your visit.
                                </p>
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="col-span-2 md:col-span-1 w-[70%]"
                            >
                                <img
                                    className="max-w-full"
                                    src={image5}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="max-w-5xl mx-auto mt-12 sm:mt-16">
                            <div className="grid grid-cols-1 gap-6 px-8 text-center md:px-0 md:grid-cols-3">
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-easing="ease-in-out"
                                    className="overflow-hidden bg-white rounded-xl"
                                >
                                    <div className="p-6">
                                        <svg
                                            className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                            />
                                        </svg>
                                        <p className="mt-6 text-lg font-medium text-gray-900">
                                            +63-316-555-0116
                                        </p>
                                        <p className="mt-1 text-lg font-medium text-gray-900">
                                            +63-446-526-0117
                                        </p>
                                    </div>
                                </div>

                                <div
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                    data-aos-duration="500"
                                    data-aos-easing="ease-in-out"
                                    className="overflow-hidden bg-white rounded-xl"
                                >
                                    <div className="p-6">
                                        <svg
                                            className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                            />
                                        </svg>
                                        <p className="mt-6 text-lg font-medium text-gray-900">
                                            puertoprincesa@palawan.com
                                        </p>
                                        <p className="mt-1 text-lg font-medium text-gray-900">
                                            tourism@palawan.com
                                        </p>
                                    </div>
                                </div>

                                <div
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                    data-aos-duration="500"
                                    data-aos-easing="ease-in-out"
                                    className="overflow-hidden bg-white rounded-xl"
                                >
                                    <div className="p-6">
                                        <svg
                                            className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                        </svg>
                                        <p className="mt-6 text-lg font-medium leading-relaxed text-gray-900">
                                            Puerto Princesa, Palawan,
                                            Philippines
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                data-aos="zoom-in"
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out"
                                className="mt-6 overflow-hidden bg-white rounded-xl"
                            >
                                <div className="px-6 py-12 sm:p-12">
                                    <h3 className="text-3xl font-semibold text-center text-gray-900">
                                        Send us a message
                                    </h3>

                                    <form
                                        action="#"
                                        method="POST"
                                        className="mt-14"
                                    >
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
                                            <div>
                                                <label
                                                    for=""
                                                    className="text-base font-medium text-gray-900"
                                                >
                                                    Your name
                                                </label>
                                                <div className="mt-2.5 relative">
                                                    <input
                                                        type="text"
                                                        name=""
                                                        id=""
                                                        placeholder="Enter your full name"
                                                        className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label
                                                    for=""
                                                    className="text-base font-medium text-gray-900"
                                                >
                                                    Email address
                                                </label>
                                                <div className="mt-2.5 relative">
                                                    <input
                                                        type="email"
                                                        name=""
                                                        id=""
                                                        placeholder="Enter your full name"
                                                        className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label
                                                    for=""
                                                    className="text-base font-medium text-gray-900"
                                                >
                                                    Phone number
                                                </label>
                                                <div className="mt-2.5 relative">
                                                    <input
                                                        type="tel"
                                                        name=""
                                                        id=""
                                                        placeholder="Enter your full name"
                                                        className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label
                                                    for=""
                                                    className="text-base font-medium text-gray-900"
                                                >
                                                    Company name
                                                </label>
                                                <div className="mt-2.5 relative">
                                                    <input
                                                        type="text"
                                                        name=""
                                                        id=""
                                                        placeholder="Enter your full name"
                                                        className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <label
                                                    for=""
                                                    className="text-base font-medium text-gray-900"
                                                >
                                                    Message
                                                </label>
                                                <div className="mt-2.5 relative">
                                                    <textarea
                                                        name=""
                                                        id=""
                                                        placeholder=""
                                                        className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600"
                                                        rows="4"
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <button
                                                    type="submit"
                                                    className="inline-flex items-center justify-center w-full px-4 py-4 mt-2 text-base font-semibold text-white transition-all duration-200 bg-[#2A9D8F] border border-transparent rounded-md focus:outline-none hover:bg-opacity-75"
                                                >
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
