import * as React from "react";

import data from "./data/data";
import tourOperator from "./data/tourOperator";
import { Link } from "react-router-dom";
import cusCss from "../../assets/css/home.module.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import { homepageContext } from "../../App";
import { dataserviceget } from "../../DataService";
import { useEffect } from "react";
import { InputText } from "primereact/inputtext";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    outline: 0,
    border: "none",
    borderRadius: "20px",
    p: 4,
};

export default function ThirdPage() {
    const { homepage, setHomePage } = useContext(homepageContext);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [openPackaage, setOpenPackaage] = React.useState(false);
    const [selectedOperator, setselectedOperator] = React.useState({});
    //Tour States
    const [tourTitle, setTourTitle] = React.useState("");
    const [tourDesc, setTourDesc] = React.useState("");
    const [tourImg, setTourImg] = React.useState("");
    const [tourImg1, setTourImg1] = React.useState("");
    const [tourImg2, setTourImg2] = React.useState("");
    const [tourId, settourId] = React.useState("");

    //Tour Operator States
    const [operatorName, setOperatorName] = React.useState("");
    const [operatorBG, setOperatorBG] = React.useState("");

    //Color Randomizer
    const colors = [
        "#B4ADA3",
        "#BFB59E",
        "#CAB7A2",
        // "#858786",
    ];
    const fetchPackageData = (id) => {
        dataserviceget("getPackageByDestination", { tourdestination_id: id })
            .then(function (data) {
                if (data.resultKey == 1) {
                    let resdata = data.resultValue;
                    setHomePage((prevState) => ({
                        ...prevState,
                        package: resdata,
                    }));
                    setFilteredUsers(resdata);
                }
            })
            .catch(function (edata) {});
    };
    //Open Modal
    const handleOpen = (e) => {
        const { id } = e.currentTarget.id;
        //alert(e.currentTarget.id);
        var destination = homepage?.destination.find(
            (x) => x.id == e.currentTarget.id
        );
        setTourTitle(destination.name);
        setTourDesc(destination.description);
        setTourImg(`${destination.image}`);
        setTourImg1(`${destination.image1}`);
        setTourImg2(`${destination.image2}`);
        settourId(e.currentTarget.id);
        setHomePage((prevState) => ({
            ...prevState,
            package: [],
        }));
        fetchPackageData(e.currentTarget.id);

        setOpen(true);
    };

    //Close Modal
    const handleClose = () => {
        setTourTitle("");
        setTourDesc("");
        setOpen(false);
    };

    //Open Modal
    const handleOpenPackage = (e) => {
        const { id } = e.currentTarget.id;
        //alert(e.currentTarget.id);
        var packagedata = homepage?.package.find(
            (x) => x.id == e.currentTarget.id
        );
        setselectedOperator(packagedata);
        setOpen(false);
        setOpenPackaage(true);
    };

    //Close Modal
    const handleClosePackage = () => {
        setOpenPackaage(false);
        setselectedOperator({});
    };

    const OpenBooking = () => {
        //console.log(selectedOperator);
        navigate(
            "/booking/destionation/" +
                tourId +
                "/touroperatorid/" +
                selectedOperator?.operator_id +
                "/packageid/" +
                selectedOperator?.id
        );
    };
    const numberFormat = (value) =>
        new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "PHP",
        }).format(value);
    const MAX_LENGTH = 100;

    useEffect(() => {
        console.log(homepage);
    }, [homepage]);

    const [searchItem, setSearchItem] = React.useState("");
    const [filteredUsers, setFilteredUsers] = React.useState(homepage?.package);
    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm);

        const filteredItems = homepage?.package.filter((user) =>
            user.operator.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredUsers(filteredItems);
        debugger;
    };

    return (
        <>
            <section className="relative -mt-[5rem] bg-start-image bg-cover bg-no-repeat flex flex-col gap-10 items-center w-full   md:rounded-bl-[10rem]">
                {/* <div className="hidden custom-shape-divider-top-1686037844 md:block">
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            className="shape-fill"
                        ></path>
                    </svg>
                </div> */}
                {/* <div className="absolute backdrop-blur-sm backdrop-brightness-50 h-full w-full   md:rounded-bl-[10rem]" /> */}
                <div className="w-4/5 pt-10 md:mt-[5rem] text-white lg:w-1/3 md:w-1/2 md:self-start lg:ml-40 md:ml-20">
                    <h1 className="text-4xl font-bold drop-shadow-lg">
                        Adventure awaits!
                    </h1>
                    <br />
                    <div className="text-xl drop-shadow-lg">
                        Find your slice of paradise on Boracay's tranquil
                        shores, where relaxation and adventure harmoniously
                        coexist.
                    </div>
                </div>
                <div className="grid w-full grid-cols-4 gap-3 mb-20 text-white place-items-center md:gap-10 md:w-4/5 drop-shadow-lg">
                    {(() => {
                        const rows = [];
                        if (homepage?.destination.length > 0) {
                            for (
                                let i = 0;
                                i < homepage?.destination.length;
                                i++
                            ) {
                                var item = homepage?.destination[i];

                                rows.push(
                                    <>
                                        <div
                                            key={item.id}
                                            name={item.id}
                                            onClick={(e) => handleOpen(e)}
                                            id={item.id}
                                            data={item}
                                            data-aos="fade-right"
                                            className={`overflow-hidden relative cursor-pointer hover:bg-gray-900/25 font-bold md:rounded-xl grid place-items-center w-full h-[15rem] col-span-4 ${
                                                item.id === 1
                                                    ? "md:col-span-3"
                                                    : ""
                                            } ${
                                                item.length === item.id
                                                    ? "md:col-start-2 md:col-span-3"
                                                    : ""
                                            }`}
                                            style={{
                                                backgroundImage: `url(${item.image})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                            }}
                                        >
                                            <div className="absolute top-0 left-0 w-full h-full transition-all bg-black opacity-50 hover:opacity-25" />
                                            <p
                                                className="drop-shadow-lg"
                                                style={{ fontSize: "3rem" }}
                                            >
                                                {item.name}
                                            </p>
                                        </div>
                                    </>
                                );
                            }
                            return rows;
                        }
                    })()}
                </div>
            </section>
            <Modal size="xl" show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title> {tourTitle}</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-col justify-center p-2">
                        <div className="grid grid-cols-2 gap-3 place-items-center">
                            {/* Main Image */}
                            <div className="relative w-full col-span-2 overflow-hidden">
                                <div className="px-5 z-10 absolute text-white lg:gap-0 gap-5 flex flex-col w-full rounded-t-[20px]">
                                    <header className="text-5xl drop-shadow-lg py-[2rem] text-center font-bold">
                                        {tourTitle}
                                    </header>
                                    <p className="px-2 lg:w-1/2">{tourDesc}</p>
                                    <p className="self-end px-2 text-right lg:w-1/2">
                                        {tourDesc}
                                    </p>
                                </div>
                                <div className="absolute top-0 left-0 w-full h-full transition-all bg-black opacity-50 rounded-xl hover:opacity-25" />
                                <img
                                    className="w-full bg-black rounded-xl"
                                    src={tourImg}
                                    alt=""
                                />
                            </div>
                            {/* Image 2 */}
                            <div className="relative col-span-2 lg:col-span-1 width100">
                                <div className="absolute top-0 left-0 w-full h-full transition-all bg-black opacity-50 rounded-xl hover:opacity-25" />
                                <img
                                    className="rounded-xl"
                                    src={tourImg1}
                                    style={{
                                        maxHeight: "400px",
                                        minHeight: "400px",
                                        width: "100%",
                                    }}
                                    alt=""
                                />
                            </div>
                            {/* Image 3 */}
                            <div className="relative col-span-2 lg:col-span-1 width100">
                                <div className="absolute top-0 left-0 w-full h-full transition-all bg-black opacity-50 rounded-xl hover:opacity-25" />
                                <img
                                    className="rounded-xl"
                                    src={tourImg2}
                                    style={{
                                        maxHeight: "400px",
                                        minHeight: "400px",
                                        width: "100%",
                                    }}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="mt-20 mb-10 text-5xl font-bold text-center">
                            Choose your travel partner:
                        </div>
                        <div className="mb-4 ">
                            <InputText
                                className="w-1/2"
                                name="firstname"
                                placeholder="Type to search"
                                value={searchItem}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            {(() => {
                                const rows = [];
                                let packagedata = filteredUsers;
                                //homepage?.package;
                                //console.log(tourId);
                                if (packagedata?.length > 0) {
                                    for (
                                        let index = 0;
                                        index < packagedata.length;
                                        index++
                                    ) {
                                        const opp = packagedata[index];
                                        const randomColor = "#696969";
                                        rows.push(
                                            <>
                                                <div
                                                    key={opp.id}
                                                    style={{
                                                        backgroundColor:
                                                            randomColor,
                                                    }}
                                                    className="flex flex-col items-center justify-center w-full gap-3 px-10 py-5 border-2 rounded-xl lg:flex-row lg:justify-between"
                                                >
                                                    <div className="text-white">
                                                        <p className="text-3xl font-bold text-white">
                                                            {
                                                                opp?.operator
                                                                    ?.name
                                                            }
                                                        </p>
                                                        <sub>{opp?.name}</sub>
                                                        <br />
                                                        <sub>
                                                            {`${opp?.operator?.description
                                                                .toString()
                                                                .substring(
                                                                    0,
                                                                    MAX_LENGTH
                                                                )}...`}{" "}
                                                            <a
                                                                // onClick={() =>
                                                                //     navigate(
                                                                //         "/TourOperator"
                                                                //     )
                                                                // }
                                                                style={{
                                                                    color: "#1021e8",
                                                                }}
                                                            >
                                                                Visit
                                                            </a>
                                                        </sub>
                                                    </div>
                                                    <div
                                                        // onClick={() => {
                                                        //     handleOpenPackage(
                                                        //         opp
                                                        //     );
                                                        // }}
                                                        name={opp.id}
                                                        onClick={(e) =>
                                                            handleOpenPackage(e)
                                                        }
                                                        id={opp.id}
                                                        className="self-auto px-16 py-2 mb-5 bg-white rounded-full drop-shadow-lg lg:self-end"
                                                    >
                                                        BOOK
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    }
                                    return rows;
                                }
                            })()}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={openPackaage} onHide={handleClosePackage}>
                <Modal.Header closeButton>
                    <Modal.Title> Book Tour </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {JSON.stringify(selectedOperator)} */}
                    {(() => {
                        const rows = [];
                        let filtertouroperatordata = selectedOperator;
                        if (filtertouroperatordata?.id != undefined) {
                            rows.push(
                                <>
                                    <div
                                        key={filtertouroperatordata?.id}
                                        className="grid justify-center grid-cols-2 gap-3 px-3"
                                    >
                                        {/* Tour Operator Banner */}
                                        <div className="col-span-2 place-items-center">
                                            <div className="py-5 bg-[#264653] text-center font-bold text-white text-3xl rounded-xl">
                                                {filtertouroperatordata?.name}
                                            </div>
                                        </div>

                                        {/* Tour Title */}
                                        {/* <div className="col-span-2 col-start-1 mt-20 mb-10 text-2xl font-bold text-center">
                                            {tourTitle}
                                            <sub></sub>
                                        </div> */}

                                        {/* Tour Description */}
                                        <div className="col-span-2">
                                            {selectedOperator.description}
                                        </div>

                                        {/* Tour Description & Image */}
                                        <div className="grid grid-cols-2 col-span-2 gap-3 place-items-center">
                                            <div className="col-span-2 lg:col-span-1">
                                                <div
                                                    className="content"
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedOperator.description1,
                                                    }}
                                                ></div>
                                                {/* <ul className="pckdisc">
                                                    <li>
                                                        {
                                                            selectedOperator.description1
                                                        }
                                                    </li>
                                                    <li>
                                                        {
                                                            selectedOperator.description1
                                                        }
                                                    </li>
                                                    <li>
                                                        {
                                                            selectedOperator.description1
                                                        }
                                                    </li>
                                                </ul> */}
                                            </div>

                                            <div className="relative col-span-2 lg:col-span-1">
                                                {/* Tour Title */}
                                                <div className="absolute col-span-2 col-start-1 mt-20 mb-10 text-2xl font-bold text-center text-white inset-20">
                                                    {tourTitle}
                                                    <sub></sub>
                                                </div>
                                                <img
                                                    src={tourImg}
                                                    alt="asdasd"
                                                />
                                            </div>
                                        </div>

                                        {/* Fee Breakdown */}
                                        <div
                                            className="w-full col-span-2 gap-2 mt-10 place-items-center"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {/* <div className="col-span-2 lg:col-span-1">
                                                <ul className="pckdisc">
                                                    <li>
                                                        {
                                                            selectedOperator.chargeinfo
                                                        }
                                                    </li>
                                                    <li>
                                                        {
                                                            selectedOperator.chargeinfo
                                                        }
                                                    </li>
                                                    <li>
                                                        {
                                                            selectedOperator.chargeinfo
                                                        }
                                                    </li>
                                                </ul>
                                            </div> */}

                                            <div className="col-span-2 lg:col-span-1">
                                                <ul>
                                                    {/* <li>Entrance Fee:</li>
                                                    <li>Boat Tour Fee:</li>
                                                    <li>
                                                        Environmental Fee:
                                                    </li>
                                                    <li>
                                                        Audio Guide
                                                        (Optional):
                                                    </li> */}
                                                    <li>
                                                        Total Cost (Including
                                                        Entrance, Boat Tour, and
                                                        Environmental Fee):
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-span-2 lg:col-span-1">
                                                <br />
                                                {/* <span>
                                                    {numberFormat(0)}
                                                </span>{" "}
                                                <br />
                                                <span>
                                                    {numberFormat(0)}
                                                </span>
                                                <br />
                                                <span>
                                                    {numberFormat(0)}
                                                </span>
                                                <br />
                                                <span>
                                                    {numberFormat(0)}
                                                </span>
                                                <br /> */}
                                                <span>
                                                    {numberFormat(
                                                        selectedOperator.totalcharge
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                            return rows;
                        }
                    })()}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            OpenBooking();
                        }}
                    >
                        Book
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
