export default [
    {
        id: 1,
        tourName: "Underground River",
        tourDescription: [
            "Embark on an extraordinary adventure through the hidden depths of nature with our captivating underground river tour. Step into a world of enchantment as we take you on a journey through an awe-inspiring underground river system, where stunning natural formations and ancient wonders await.",
            "As you arrive at the entrance to the underground river, you'll be greeted by the cool and mysterious atmosphere that surrounds this hidden gem. Equipped with safety gear and accompanied by experienced guides, you'll board a boat specially designed for navigating the underground passages.",
        ],
        img: "./images/lxonyeuu3jwtavzldwjp.webp",
    },
    {
        id: 2,
        tourName: "City Heritage Tour",
        tourDescription: [
            "Embark on an extraordinary adventure through the hidden depths of nature with our captivating underground river tour. Step into a world of enchantment as we take you on a journey through an awe-inspiring underground river system, where stunning natural formations and ancient wonders await.",
            "As you arrive at the entrance to the underground river, you'll be greeted by the cool and mysterious atmosphere that surrounds this hidden gem. Equipped with safety gear and accompanied by experienced guides, you'll board a boat specially designed for navigating the underground passages.",
        ],
        img: "./images/ug38eeg3xoqajd3wgezx.webp",
    },
    {
        id: 3,
        tourName: "Island Hopping Tour",
        tourDescription: [
            "Embark on an extraordinary adventure through the hidden depths of nature with our captivating underground river tour. Step into a world of enchantment as we take you on a journey through an awe-inspiring underground river system, where stunning natural formations and ancient wonders await.",
            "As you arrive at the entrance to the underground river, you'll be greeted by the cool and mysterious atmosphere that surrounds this hidden gem. Equipped with safety gear and accompanied by experienced guides, you'll board a boat specially designed for navigating the underground passages.",
        ],
        img: "./images/uepi05jkfw2ptfccqe0d.webp",
    },
    {
        id: 4,
        tourName: "Underground River",
        tourDescription: [
            "Embark on an extraordinary adventure through the hidden depths of nature with our captivating underground river tour. Step into a world of enchantment as we take you on a journey through an awe-inspiring underground river system, where stunning natural formations and ancient wonders await.",
            "As you arrive at the entrance to the underground river, you'll be greeted by the cool and mysterious atmosphere that surrounds this hidden gem. Equipped with safety gear and accompanied by experienced guides, you'll board a boat specially designed for navigating the underground passages.",
        ],
        img: "./images/lxonyeuu3jwtavzldwjp.webp",
    },
    {
        id: 5,
        tourName: "City Heritage Tour",
        tourDescription: [
            "Embark on an extraordinary adventure through the hidden depths of nature with our captivating underground river tour. Step into a world of enchantment as we take you on a journey through an awe-inspiring underground river system, where stunning natural formations and ancient wonders await.",
            "As you arrive at the entrance to the underground river, you'll be greeted by the cool and mysterious atmosphere that surrounds this hidden gem. Equipped with safety gear and accompanied by experienced guides, you'll board a boat specially designed for navigating the underground passages.",
        ],
        img: "./images/ug38eeg3xoqajd3wgezx.webp",
    },
]
