import { Calendar } from "primereact/calendar"
import React, { useContext } from "react"
import { useRef } from "react"
import { useState } from "react"
import { Toast } from "primereact/toast"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Select from "react-select"
import { InputNumber } from "primereact/inputnumber"
import { globalContext, homepageContext } from "../../App"
import { useParams } from "react-router-dom"
import { dataserviceget } from "../../DataService";
import moment from "moment"
import { useEffect } from "react"
import { v4 as uuid } from "uuid"
export default function BookingInfo() {

    let params = useParams();
    const { global, setGlobal } = useContext(globalContext)
    const { homepage, setHomePage } = useContext(homepageContext)
    const [date, setDate] = useState(null)
    const toast = useRef(null)

    const [selectedOperator, setSelectedOperator] = useState()
    const [selectedDestination, setSelectedDestination] = useState()
    const [selectedPackage, setSelectedPackage] = useState()
   
    
    const [destinationOptions, setDestinationOptions] = useState()
    const [operatorOptions, setOperatorOptions] = useState()
   
   
   
    const [value2, setValue2] = useState(1)
    const [mindate, setMindate] = useState(moment().format("YYYY-MM-DD"))
    const [packagedata, setPackagedata] = useState()

    const [isdesdisable, setIsDesDisable] = useState(false)
    const [isopdisable, setIsOpDisable] = useState(false)
    const [ispckdisable, setIsPckDisable] = useState(false)
    

    const restGlobal = () => {
        setGlobal((prevState) => ({
            ...prevState,
            traveldate: moment().format("YYYY-MM-DD"),
            numberofguest: 1,
            guestinfo: [
                {
                    firstname: "",
                    lastname: "",
                    middlename: "",
                    gender: "",
                    mobilenumber: "",
                    email: "",
                    dob: "",
                    age: 0,
                    address: "",
                    zipcode: "",
                    citizen: "",
                    citizen_desc: "",
                    country: "",
                    country_desc: "",
                    province: "",
                    province_desc: "",
                    municipality: "",
                    municipality_desc: "",
                    id: uuid(),
                },
            ],
            activeguest: 1,
            selectedpck: null,
            selectedopr: null,
        }))
    }
    
    const handleChange = (e) => {
        
        const { name, value } = e.target
        setGlobal({ ...global, [name]: value })
        e.preventDefault()
        //  setState({...global, [id]: (e.target.checked==true?e.target.checked:false)});

        //  handleGlobal(event.target.value);
    }
    const handleSelectChange = (e, f) => {
        
        switch (f) {
            case "d":
                if (e == null) {
                    setSelectedDestination(null)
                    setGlobal((prevState) => ({
                        ...prevState,
                        selecteddes: "",
                        selectedpck: "",
                        selectedopr: "",
                    }))
                } else {
                    let des = destinationOptions.find(
                        (x) => x.id == e?.value
                    )
                    setSelectedDestination(des);
                    setSelectedOperator(null);
                    setSelectedPackage(null)
                    fetchOperatorData(e?.value,false)
                    setGlobal({
                        ...global,
                        ["selecteddes"]: e?.value,
                    })
                }
                break
            case "o":
                if (e == null) {
                    setSelectedOperator(null)
                    setGlobal((prevState) => ({
                        ...prevState,

                        selectedpck: "",
                        selectedopr: "",
                    }))
                } else {
                    let opr = operatorOptions.find(
                        (x) => x.id == e?.value
                    )
                    setSelectedOperator(opr);
                    setIsPckDisable(false);
                    setSelectedPackage(null)
                    fetchPackageData(e?.value,selectedDestination.id, false)
                    setGlobal({
                        ...global,
                        ["selectedopr"]: e?.value,
                    });
                    
                    
                }
                break
            case "p":
                if (e == null) {
                    setSelectedPackage(null)
                    setGlobal((prevState) => ({
                        ...prevState,

                        selectedpck: "",
                    }))
                } else {
                    let pck = homepage?.package.find((x) => x.id == e?.value)
                    setSelectedPackage(pck)
                    setGlobal({ ...global, ["selectedpck"]: e?.value })
                }

                break
            default:
                break
        }
    }

    useEffect(() => {
        let guestdata = {
            firstname: "",
            lastname: "",
            middlename: "",
            gender: "",
            mobilenumber: "",
            email: "",
            dob: "",
            age: 0,
            address: "",
            zipcode: "",
            citizen: "",
            citizen_desc: "",
            country: "",
            country_desc: "",
            province: "",
            province_desc: "",
            municipality: "",
            municipality_desc: "",
            id: uuid(),
        }
        let guestolddata = global?.guestinfo
        if (guestolddata?.length != global?.numberofguest) {
            if (global.numberofguest > guestolddata?.length)
                guestolddata.push(guestdata)
            else guestolddata.pop()

            setGlobal({ ...global, ["guestinfo"]: guestolddata })
        }
    }, [global.numberofguest])
    
    const fetchDestinationData = () => {
        dataserviceget("gettourdestination", {})
            .then(function (data) {
                if (data.resultKey == 1) {
                    let resdata = data.resultValue;
                    ;
                    resdata.forEach(function (element) {
                        element.label = element.name;
                        element.value = element.id;
                    });
                    setDestinationOptions(resdata);
                    if(params?.destid){
                        setTimeout(() => {
                            setGlobal({
                                ...global,
                                ["selecteddes"]: params?.destid,
                            })
                        }, 10);
                       
                        let des = resdata.find(
                            (x) => x.id == params?.destid
                        )
                         setSelectedDestination(des);
                         fetchOperatorData(params?.destid,true)
                         
                        setIsDesDisable(true);
                    }
                }
            })
            .catch(function (edata) {});
    };
    const fetchOperatorData = (id,isonloadset) => {
        ;
        dataserviceget("gettouroperatordestinationid", {desid:id})
            .then(function (data) {
                if (data.resultKey == 1) {
                    let resdata = data.resultValue;
                    ;
                    resdata.forEach(function (element) {
                        element.label = element.name;
                        element.value = element.id;
                    });
                    setOperatorOptions(resdata);
                    if(isonloadset){
                        setTimeout(() => {
                            setGlobal({
                                ...global,
                                ["selectedopr"]: params?.id,
                            })
                        }, 10);
                        
                        let opr = resdata.find(
                            (x) => x.id == params?.id
                        )
                        setSelectedOperator(opr);
                        setSelectedPackage(null);
                        setIsOpDisable(true);
                        fetchPackageData(params?.id,params?.destid,true)
                        
                        
                        
                        
                    }
                    
                }
            })
            .catch(function (edata) {});
    };
    const fetchPackageData = (id,desid,isonloadset) => {
        ;
        //console.log(selectedOperator);
        dataserviceget("getPackageByDestination", {tourdestination_id:desid,opid:id})
            .then(function (data) {
                if (data.resultKey == 1) {
                    let resdata = data.resultValue;
                    setHomePage((prevState) => ({
                        ...prevState,
                        package: resdata,
                    }));
                    setPackagedata(resdata);
                    if(isonloadset){
                        let pck = resdata.find(
                            (x) => x.id == params?.pckid
                        )
                       // let pck = pckdata.find((x) => x.id == params?.pckid);
                        setSelectedPackage(pck);
                        setGlobal((prevState) => ({
                            ...prevState,
                            selectedpck:params?.pckid,
                            selectedopr:params?.id,
                            selecteddes:params?.destid

                        }));
                       
                        setIsPckDisable(true);
                    }
                }
            })
            .catch(function (edata) {});
    };
    const fetchCommonDropdownData = () => {
        dataserviceget("dropdown", {})
            .then(function (data) {
                if (data.resultKey == 1) {
                    let resdata = data.resultValue;
                    let provincedata = resdata["province"];
                    let countrydata = resdata["country"];
                    let citydata = resdata["city"];
                     countrydata.forEach(function (element) {
                        element.label = element.name;
                        element.value = element.id;
                    });
                    provincedata.forEach(function (element) {
                        element.label = element.name;
                        element.value = element.id;
                    });
                    citydata.forEach(function (element) {
                        element.label = element.name;
                        element.value = element.id;
                    });
                    setHomePage((prevState) => ({
                        ...prevState,
                       
                         fee: resdata["fee"],
                        country: countrydata,
                        province: provincedata,
                        city: citydata,
                    }));
                }
            })
            .catch(function (edata) {});
    };
    
    


    
    useEffect(() => {
      
        restGlobal();
        fetchDestinationData();
        fetchCommonDropdownData();
        setSelectedDestination(null);
        setSelectedOperator(null);
        setSelectedPackage(null);
    }, [])

    return (
        <>
            <Toast ref={toast}></Toast>

            <Form className="bg-blue-5001 grid lg:grid-cols-2 grid-cols-1 gap-3 p-3">
            <div>
                    <Form.Group as={Col} controlId="formGridTourDestination">
                        <Form.Label>Tour Destination</Form.Label>
                        <Select
                            isDisabled={isdesdisable}
                            name="selectedpck"
                            isClearable="true"
                            value={selectedDestination}
                            onChange={(e) => handleSelectChange(e, "d")}
                            options={destinationOptions}
                        />
                    </Form.Group>
                </div>
                <div>
                    <Form.Group as={Col} controlId="formGridTourOperator">
                        <Form.Label>Tour Operator</Form.Label>
                        <Select
                            isDisabled={isopdisable}
                            name="selectedpck"
                            isClearable="true"
                            value={selectedOperator}
                            onChange={(e) => handleSelectChange(e, "o")}
                            options={operatorOptions}
                        />
                        {/* <Form.Control
                                type="date"
                                name="duedate"
                                placeholder="Due date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            /> */}
                    </Form.Group>
                </div>
                <div>
                    <Form.Group as={Col} controlId="formGridTourPackage">
                        <Form.Label>Package</Form.Label>
                        <Select
                            isClearable="true"
                            onChange={(e) => handleSelectChange(e, "p")}
                            value={selectedPackage}
                            options={packagedata}
                            isDisabled={ispckdisable}
                        />
                    </Form.Group>
                </div>
                <div>
                    <Form.Group as={Col}>
                        <Form.Label>Traveling Date</Form.Label>
                        <Form.Control
                            type="date"
                            name="traveldate"
                            placeholder="Due date"
                            value={global.traveldate}
                            min={mindate}
                            onChange={(e) => handleChange(e)}
                        />
                    </Form.Group>
                </div>
                <div>
                    <Form.Group className="flex flex-col" as={Col}>
                        <Form.Label>Number of guest</Form.Label>
                        <InputNumber
                            name="numberofguest"
                            inputId="horizontal-buttons"
                            value={global?.numberofguest}
                            min={1}
                            onValueChange={(e) => handleChange(e)}
                            showButtons
                            buttonLayout="horizontal"
                            step={1}
                            decrementButtonClassName="p-button-success"
                            incrementButtonClassName="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                        />
                    </Form.Group>
                </div>
            </Form>
            {/* {JSON.stringify(global)} */}
        </>
    )
}
