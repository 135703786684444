import React from "react"

export default function Header() {
    return (
        <div>
            <main className="grid place-items-center h-screen">
                <h1>Tour Name</h1>
                <h2>Tour Description</h2>
            </main>
        </div>
    )
}
