import React, { useState, useRef, useEffect, useContext } from "react";
import { globalContext, homepageContext } from "../../App";
import { dataserviceget } from "../../DataService";
export default function PaymentInfo() {
    const { global, setGlobal } = useContext(globalContext);
    const { homepage, setHomePage } = useContext(homepageContext);
    const [traveldate, setTraveldate] = useState("10/1/2024");
    const [numberofguest, setNumberofguest] = useState(1);
    const [total, setTotal] = useState(0);
    const [feeSummary, setFeeSummary] = useState([
        {
            id: 1,
            amt: 0.1,
            name: "ETAF",
            description: "Environmental Tourism and Administrative Fee",
        },
        {
            id: 2,
            amt: 0.1,
            name: "Computer",
            description: "",
        },
        {
            id: 3,
            amt: 0.1,
            name: "Bracelet",
            description: "",
        },
        {
            id: 4,
            amt: 0,
            name: "Discount",
            description: "",
        },
    ]);
    const numberFormat = (value) =>
        new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "PHP",
        }).format(value);
    const cal = () => {
        if(homepage?.fee.length>0){
            var res = 0;
            for (let index = 0; index < homepage?.fee.length; index++) {
                const element = homepage?.fee[index];
                if (element.id == 1) {
                    res += global?.numberofguest * parseFloat(element.amount) ;
                } else {
                    res += parseFloat(element.amount);
                }
            }
            setGlobal((prevState) => ({
                ...prevState,
                totalAmount: res
    
            }));
            setTotal(res);
        }
        
    };
    
    useEffect(() => {
        cal();
    }, []);
    useEffect(() => {
        cal();
       
    }, [global?.numberofguest]);
    useEffect(() => {
        debugger;
        if(homepage?.fee.length>0){
            setFeeSummary(homepage?.fee);
            cal();
        }
        
    }, [homepage?.fee]);

    return (
        <>
            <div style={{ color: "red" }}>
                <span>*</span> Marked fields are mandatory
            </div>
            <h4>Fee Calculation</h4>
            <div>
                Tour Operator: {""}
                {numberofguest}
            </div>
            <div>
                Package Name: {""}
                {numberofguest}
            </div>
            <div>
                Traveling Date: {""}
                {traveldate}
            </div>
            <div>
                Number of guest: {""}
                {global?.numberofguest}
            </div>

            <div className="card p-3">
                {feeSummary.map((feeSummary) => (
                    <div key={feeSummary.id} className="row">
                        <div className=" col-8">
                            {feeSummary.name}
                            {feeSummary.description == "" ? (
                                ""
                            ) : (
                                <>
                                    <br></br>
                                    <sub>{feeSummary.description}</sub>
                                </>
                            )}
                        </div>
                        <div className=" col-4">
                            
                            {feeSummary.id==1? (numberFormat((global?.numberofguest * feeSummary.amount))):numberFormat(feeSummary.amount)}
                        </div>
                    </div>
                ))}
                <hr></hr>
                <div className="row custbold">
                    <div className=" col-8">Total:</div>
                    <div className=" col-4">{numberFormat(total)}</div>
                </div>
            </div>
        </>
    );
}
