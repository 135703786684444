import React, { useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import Paymentinfo from "./PaymentInfo";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { globalContext,homepageContext } from "../../App";
import background from "../../assets/images/bookingbackground.png";
import BookingInfo from "./BookingInfo";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import BookingGuest from "./BookingGuest";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useEffect } from "react"
import dataservicepost from "../../DataService";

export default function BookingSteps() {
    const { homepage, setHomePage } = useContext(homepageContext)
    //const gdata = useContext(globalContext);
    const { global, setGlobal } = useContext(globalContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedCity, setSelectedCity] = useState(null);
    const toast = useRef(null);
    const items = [
        {
            label: "Booking Information",
            command: (event) => {
                toast.current.show({
                    severity: "info",
                    summary: "First Step",
                    detail: event.item.label,
                });
            },
        },
        {
            label: "Guest Information",
            command: (event) => {
                toast.current.show({
                    severity: "info",
                    summary: "Second Step",
                    detail: event.item.label,
                });
            },
        },
    ];

    const cities = [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
    ];
    const myStyle = {
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };

    const [date, setDate] = useState(null);
    const params = useParams();
    const tourid = params?.id;
    const updateActiveIndex = (e) => {
        if (e == "n") {
            setActiveIndex((prevState) => prevState + 1);
            setGlobal({ ...global, ["activeguest"]: activeIndex + 1 });
        } else {
            setActiveIndex((prevState) => prevState - 1);
            setGlobal({ ...global, ["activeguest"]: activeIndex - 1 });
        }
    };
    const updateActiveGuest = (e) => {
        setGlobal({ ...global, ["activeguest"]: e });
    };
    const cal = () => {
        debugger;
        console.log(homepage.fee);
        var res = 0;
        var paysummary = [];
        for (let index = 0; index < homepage.fee.length; index++) {
            const element = homepage.fee[index];
            
            if (element.id == 1) {
                let amtdt=global?.numberofguest * parseFloat(element.amount);
                res += amtdt;
                paysummary.push({"desc":element.name,"amount":amtdt});
            } else {
                paysummary.push({"desc":element.name,"amount":element.amount});
                res += parseFloat(element.amount);
            }
        }
        setGlobal((prevState) => ({
            ...prevState,
            totalAmount: res,
            paymentsummary:paysummary

        }));
    }
    const pay = () => {
        debugger;
        cal();
        //validate
        if(global?.totalAmount<=0){
            return false;
        }
        //guest vaildation
        if(global?.guestinfo<=0){
            return false;
        }else{

            for (let index = 0; index < global?.guestinfo.length; index++) {
                const element = global?.guestinfo[index];
                if(element.firstname==""){
                    NotificationManager.error(`Guest #${index+1} First Name Required` , 'Click me!', 5000, () => {
                        //alert('callback');
                      });
                    return false;
                    
                }
                if(element.lastname==""){
                    NotificationManager.error(`Guest #${index+1} Last Name Required` , 'Click me!', 5000, () => {
                        //alert('callback');
                      });
                    return false;
                    
                }
                if(element.middlename==""){
                    NotificationManager.error(`Guest #${index+1} Middle Name Required` , 'Click me!', 5000, () => {
                        //alert('callback');
                      });
                    return false;
                    
                }
                if(element.gender==""){
                    NotificationManager.error(`Guest #${index+1} Gender Required` , 'Click me!', 5000, () => {
                        //alert('callback');
                      });
                    return false;
                    
                }
                if(element.email==""){
                    NotificationManager.error(`Guest #${index+1} Email Required` , 'Click me!', 5000, () => {
                        //alert('callback');
                      });
                    return false;
                    
                }
                
                
            }

        }

        dataservicepost("payment", {
            packageid:global?.selectedpck,
            operatorid:global?.selectedopr,
            desctinationid:global?.selecteddes,
            numberofguest:global?.numberofguest,
            traveldate:global?.traveldate,
            guestinfo:global?.guestinfo,
            amount: global?.totalAmount,
            contact: global?.guestinfo[0].mobilenumber,
            customer_email: global?.guestinfo[0].email,
            paymentsummary:global?.paymentsummary,
            customer_name:
                global?.guestinfo[0].firstname +
                " " +
                global?.guestinfo[0].lastname +
                " " +
                global?.guestinfo[0].middlename,
        })
            .then(function (data) {
                ;
                if (data.resultKey == 1) {
                    window.location.href = data?.resultValue?.results?.data.url;
                }
            })
            .catch(function (edata) {});
    };
    useEffect(() => {
        cal();
    },[homepage.fee])
    return (
        <>
         <NotificationContainer/>
            <div
                style={myStyle}
                className="relative flex justify-center h-screen py-3"
            >
                {/* Background blur and filter */}
                <div className="absolute top-0 left-0 w-full h-screen bg-gray-900/75 backdrop-blur-sm" />

                <div className="flex flex-col w-4/5 gap-3">
                    {/* Steps */}
                    <div className="p-2 card">
                        <Toast ref={toast}></Toast>
                        <Steps
                            model={items}
                            activeIndex={activeIndex}
                            onSelect={(e) => setActiveIndex(e.index)}
                            readOnly={false}
                        />
                    </div>

                    {/* Main Content */}

                    <div className="h-full overflow-y-auto max-h-fit">
                        {/*STEP 1: Booking Info */}
                        <div
                            className={`gap-3 justify-between lg:flex-row flex-col
                                        ${
                                            activeIndex != 0 ? "hidden" : "flex"
                                        }`}
                        >
                            <div className="w-full card">
                                <BookingInfo />
                            </div>
                            <div className="w-full p-3 card lg:w-fit">
                                <Paymentinfo />
                            </div>
                        </div>
                        {/* STEP 2:  Guest Info*/}
                        <div
                            className={`flex
                        ${activeIndex != 1 ? "hidden" : "row"}`}
                        >
                            <div className="flex flex-col justify-between w-full gap-3 md:flex-row">
                                {/* Guest List & Booking Guest */}
                                <div className="flex w-full gap-3">
                                    {/* Guest List */}
                                    <div className="w-1/2 lg:w-1/4 card">
                                        <Button
                                            style={{
                                                marginBottom: "1px",
                                            }}
                                            label="Upload"
                                        />
                                        {global.guestinfo &&
                                            global.guestinfo.map(
                                                (guestinfo, index) => {
                                                    var counter = index + 1;
                                                    var severity =
                                                        counter ==
                                                        global.activeguest
                                                            ? "success"
                                                            : "primary";
                                                    return (
                                                        <>
                                                            <Button
                                                                severity={
                                                                    severity
                                                                }
                                                                style={{
                                                                    marginBottom:
                                                                        "1px",
                                                                }}
                                                                label={
                                                                    "Guest " +
                                                                    counter
                                                                }
                                                                onClick={(e) =>
                                                                    updateActiveGuest(
                                                                        counter
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                    );
                                                }
                                            )}
                                    </div>
                                    {/* Booking List */}
                                    <div className="w-full card">
                                        <BookingGuest />
                                    </div>
                                </div>

                                {/* Payment Info */}
                                <div className="w-full p-3 card lg:w-fit">
                                    <Paymentinfo />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Button Group */}
                    <div className="card">
                        <div className="flex justify-between">
                            {/* Back */}
                            <div className="col-6">
                                {activeIndex != 0 ? (
                                    <Button
                                        label="Back"
                                        icon="pi pi-check"
                                        onClick={() =>
                                            setActiveIndex(activeIndex - 1)
                                        }
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            {/* Next */}
                            <div className="col-6 rightalign">
                                {activeIndex == 1 ? (
                                    <Button
                                        label="Pay"
                                        onClick={(e) => pay()}
                                    />
                                ) : (
                                    <Button
                                        label="Next"
                                        icon="pi pi-arrow-right"
                                        iconPos="right"
                                        onClick={(e) => updateActiveIndex("n")}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
