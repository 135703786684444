import common from "./common";
const dataservicepost = async (controller, reqbody) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqbody),
    };
    console.warn('req post--',new Date(),'--',requestOptions);
    const url = common.baseurl + controller;
    return await fetch(url, requestOptions)
        .then(async (response) => {
            const data = await response.json();
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } else {
                console.warn('res post',new Date(),'--',data);
                return data;
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
const dataserviceget = async (controller, reqbody) => {
    
    
    var url = common.baseurl + controller;
    if(JSON.stringify(reqbody)!=="{}"){
        url=url+"?"+new URLSearchParams(reqbody);
    }
    console.warn('req get--',new Date(),'--',url);
    return await fetch(url)
        .then(async (response) => {
            const data = await response.json();
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } else {
                console.warn('res get',new Date(),'--',data);
                return data;
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqbody),
    };

    return await fetch(url, requestOptions)
        .then(async (response) => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } else {
                return data;
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
export default dataservicepost;
export { dataserviceget };
